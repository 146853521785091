import React, { useRef, useState, useEffect, useCallback } from "react";
import Layout from "@components/layout";
import { GatsbyImage } from "gatsby-plugin-image"

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import Animation modules
import { motion } from "framer-motion"

// Import SVG
import hatena_icon from '@images/hatena_icon.svg'; // ？マークの代わりのダミー
import yajieushi_icon from '@images/yajirushi-wh.svg'; // 
import yajirushi_icon02 from '@images/2step_yajirushi-sp.svg'; // 

import topphone from '@images/phone-image.webp';
import topimage01 from '@images/top-image01_2.webp';
import pay_img01 from '@images/pay_img01.webp'; // 
import pay_img02 from '@images/pay_img02.webp'; // 
import pay_img03 from '@images/pay_img03.svg'; // 
import how_img03 from '@images/how_img03.webp'; // 


// 関数呼び出し
import { FixedFoot } from "@components/footer.js";
import { SeoContents } from "@components/seo-data.js" // Seo情報設定


const PropPayment = () => {
  let fixedItem = FixedFoot( 'nomal' ); // 固定フッター
 
  // Seo情報設定
  const pathName = 'payment';
  const seoTag = SeoContents({ Path:pathName });

  let setArray;
  let pushArray = [];
  const pickupPath = "/";
  const LinkGuidance = "/guidance#how";
  const LinkHowto = "/howto#how";
  const LinkPayment = "/payment#pay";
  
  

  return (
  <>
    <Layout>
      {seoTag}
      <div className="bg-[#F6F6F6] md:pb-[70px] pb-2">
        <div className="px-5 max-w-xl mx-auto md:max-w-5xl relative md:h-[330px] xl:overflow-visible overflow-hidden">
        <div className="pb-10 pt-0 md:pt-10 max-w-[800px] md:absolute md:left-5 md:top-[0%]">
          <h2 className="md:text-5xl text-3xl font-black mb-10 text-left pt-5">サービスのご案内</h2>
          <h3 className="md:text-3xl text-2xl font-black mb-5 text-left  text-[#1F55A6]">Just tradeは日本発のプロップファームとして<br></br>独自の審査基準を設けております。</h3>
            <p className="md:text-base text-base  font-black md:max-w-[500px] xl:max-w-[600px]">また、トレーダーの皆様にリスクなしで取引していただけるだけでなく<br></br>
               出金までの手続き状況をすべてご覧いただけるようなプラットフォームを構築しております。
            </p>
          </div>
          <div className="max-w-[650px] md:absolute md:right-[-23%] md:top-10">
          <motion.div className=" mx-auto text-center md:block md:mt-10 xl:mt-[0px] md:ml-[200px] xl:ml-[40px]" initial={{ opacity:0 ,x:100 }} whileInView={{ opacity:1 ,x:0 }} transition={{ duration: 1 }} viewport={{ once: true }}>
              <img className="mb-5 mx-auto h-auto" alt="" src={topimage01}/>
            </motion.div>
          </div>
        </div>

        </div>
              {/* サブメニュー */}
              <div className="bg-[#F6F6F6] ">
                <a id="pay" className="jump_point"></a>
<div className="max-w-5xl mx-auto flex px-5">
<a href={LinkGuidance} className="md:text-base text-xs text-center md:py-4 py-2 px-2 md:px-10 w-1/3 bg-white mr-3 font-semibold border-b-[15px] border-[#F6F6F6] hover:text-[#1F55A6] text-[#999999] hover:border-white ">サービスの<br className="res_br"></br>内容</a>
<a href={LinkHowto}  className="md:text-base text-xs text-center md:py-4 py-2 px-2 md:px-10 w-1/3 bg-white mr-3 font-semibold border-b-[15px] border-[#F6F6F6] hover:text-[#1F55A6] text-[#999999] hover:border-white ">使い方</a>
<a href={LinkPayment}  className="md:text-base text-xs text-center md:py-4  py-2 px-2 md:px-10 w-1/3 bg-white ml-3 font-semibold border-b-[15px] border-white text-[#1F55A6] ">出金について</a>
</div>
      </div>
       






        <div className="px-5 max-w-xl mx-auto md:max-w-5xl  mt-[70px] ">
                {/* チャレンジ期間・表 */}
          <h3 className="md:text-3xl text-2xl font-black mb-5 text-left  text-[#1F55A6]">スムーズな出金システム</h3>
           <p className="mb-[70px]">Just tradeではお客様に安心して取引していただけるよう出金までの日数を最小限に抑えております。<br></br>
             また、資金が実際に着金するまでの出金状況も把握できるような独自のツールもご用意しております。
           </p>
        </div>

      {/* チャレンジコース・表 */}
      <div className="bg-[#02478E] py-20">
        <div className="max-w-5xl mx-auto px-5">
          <h3 className="md:text-3xl text-2xl font-black mb-10 text-left  text-white">出金申請から支払いまでの流れ</h3>



         <div className="p-10  bg-white mb-10  mx-auto w-full rounded-md md:flex justify-between">
            <div className="mr-5 md:mb-0 mb-5">
              <h4 className="text-[#1F55A6] text-xl mb-5 font-semibold">マイページにログイン</h4>
              <p>登録したパスワードを使用してマイページにログインする</p>
            </div>
            <p className="max-w-[332px]"><img src={how_img03} alt=""></img></p>
         </div>
<p className="flex justify-center mb-10"><img src={yajieushi_icon} alt=""></img></p>

         <div className="p-10  bg-white mb-10  mx-auto w-full rounded-md md:flex justify-between">
           <div className="mr-5 md:mb-0 mb-5">
              <h4 className="text-[#1F55A6] text-xl mb-5 font-semibold">マイページで出金可能な利益を確認</h4>
              <p>マイページでの「出金」ページにアクセスすると、その時点で出金可能な金額が確認できます。</p>
            </div>
            <p className="max-w-[332px]"><img src={pay_img01} alt=""></img></p>
              </div>
<p className="flex justify-center mb-10"><img src={yajieushi_icon} alt=""></img></p>


         <div className="p-10  bg-white mb-10  mx-auto w-full rounded-md md:flex justify-between">
           <div className="mr-5 md:mb-0 mb-5">
              <h4 className="text-[#1F55A6] text-xl mb-5 font-semibold">出金可能日を確認の上、出金申請を行う</h4>
              <p>Just tradeでは月に2回のサイクル（毎月14日と24日）で出金が可能です。<br></br>
              出金申請を完了するためには全てのポジションを閉じていただく必要があります。
            </p>
            </div>
            <p className="max-w-[332px]"><img src={pay_img02} alt=""></img></p>
            </div>
<p className="flex justify-center mb-10"><img src={yajieushi_icon} alt=""></img></p>

         <div className="p-10  bg-white mb-10  mx-auto w-full rounded-md md:flex justify-between">
            <div className="md:mr-9 max-w-[560px] md:mb-0 mb-5">
              <h4 className="text-[#1F55A6] text-xl mb-5 font-semibold">Just tradeから支払い</h4>
              <p>出金申請を完了後、2営業日から3営業日後までにトレーダーの指定した口座に反映されます。</p>
            </div>
            <p className="max-w-[332px] mx-auto"><img src={pay_img03} alt="" className="mx-auto md:mx-0"></img></p>
            </div>
        </div>
      </div>

        {/* 固定フッター */}
        {fixedItem}
      </Layout>
  </>
  )
}
export default PropPayment

// モーダル用funciton
export function ModalItem( item ) {

  // (※変更不要)モーダル用設定
  const[isModalOpen,setIsModalOpen]=useState(false)
  const closeModal= useCallback(() =>{
    setIsModalOpen(false)
    document.removeEventListener('click',closeModal)
  },[])
  useEffect(()=>{
    return ()=>{
      document.removeEventListener('click',closeModal)
    }
  },[closeModal])
  function openModal(event){
    setIsModalOpen(true)
    document.addEventListener('click',closeModal)
    event.stopPropagation()
  }

  // 子コンポーネント（モーダル）
  function Modal(props){
    const startYOffset = React.useRef(UseScrollPosition())
    startYOffset.current = window.pageYOffset // スクロール位置の左上のY軸座標
    // 調整　モーダル中身（テキスト以外）の設定
    return(
      <div id="modal" className="modal !z-20 text-black w-full text-right" onClick={(event)=>{event.stopPropagation()}}>
        {/* ここでモーダル表示位置を調整 */}
        <div className="absolute left-1/2 w-full mt-[32%] max-w-[320px] -ml-[160px] touch-none" style={{top: startYOffset.current}}> 
          {/* 出力データ */}
          <div className="!text-base absolute w-full bg-white z-20 max-w-[326px]">{item}</div>
          {/* この中のエリア押すと閉じる、閉じるボタン用*/}
          <button onClick={props.onClick} className="w-[30px] h-[30px] bg-black top-0 z-20 relative">
            <div className="!text-base text-white">×</div>
          </button>
        </div>
        {/*この中のエリア押すと閉じる、透過背景用 */}
        <div className="">
          <button onClick={props.onClick} className="w-full bg-gray-700 absolute top-[0px] left-5 h-[8030px] z-10 opacity-50 touch-none"></button>
        </div>
      </div>
    )
  }
  // 親コンポーネント（ボタン）
  return (
    <>
      <div>
        <button onClick={(event)=>{openModal(event)}} className=""> 
          <span className={"" + (isModalOpen? " hidden" :"")}>
            <img alt="" src={hatena_icon}/> {/* 調整　？マークアイコンに差し替える */}
          </span>
          {isModalOpen? <Modal onClick={(event)=>{closeModal(event)}} /> :""}
        </button>
      </div>
    </>
  );
}

// (※変更不要)モーダルスクロール位置取得用function
function UseScrollPosition() {
  const isProcessing = useRef(false)
  const [positionY, setPositionY] = useState(0)
  useEffect(() => {
    const handler = () => {
      if (isProcessing.current) return
      isProcessing.current = true
      window.requestAnimationFrame(() => { // Window.requestAnimationFrame()でpositionYステートの更新を間引く
        isProcessing.current = false
        setPositionY(window.scrollY)
      })
    }
    document.addEventListener('scroll', handler, { passive: true }) // スクロールイベントの登録
    return () => { // スクロールイベントの解除
      document.removeEventListener('scroll', handler)
    }
  }, [])
  return positionY // 取得したスクロール量をreturn
}
